import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import { Flex } from "../components/FlexBox";
import { Headline } from "../components/parts/typography";

const Datenschutz = ({ data }) => {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;
    return (
        <Layout contentTopMargin="0">
            <Flex
                flexDirection="column"
                alignSelf="center"
                width="100%"
                maxWidth="876px"
            >
                <Headline>{frontmatter.title}</Headline>
                <div
                    style={{ fontSize: "15px", fontWeight: 300 }}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </Flex>
        </Layout>
    );
};

export default Datenschutz;

export const datenschutzQuery = () => {
    graphql`
        query {
            markdownRemark(frontmatter: { title: { eq: "DATENSCHUTZ" } }) {
                frontmatter {
                    title
                }
                html
            }
        }
    `;
};
